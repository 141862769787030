// FeaturedProducts.scss

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;700&display=swap');

.featured-products {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 1rem;
  direction: rtl;
  font-family: "Noto Sans Hebrew", sans-serif;

  .header {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: right;
    margin-bottom: 2rem;
  }

  .features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 2rem;
    margin-bottom: 3rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .icon {
          width: 4rem;
          height: 4rem;
          object-fit: contain; // This ensures the entire image is visible
      
          &[src="/placeholder.png"] {
            opacity: 0.5; // Makes placeholder images semi-transparent
          }
        }

      .text {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }

  .description {
    color: #333;
    margin-bottom: 3rem;
    text-align: right;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .catalogue-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: right;
  }

  .catalogue {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    justify-content: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .error {
    color: #ef4444;
    text-align: right;
    font-size: 1.2rem;
  }
}