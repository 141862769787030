.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .popup-content {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        max-width: 80%;
        width: 400px;

        h2 {
            margin-bottom: 10px;
        }

        button {
            margin-top: 15px;
            padding: 5px 15px;
            background-color: #2879fe;
            color: white;
            border: none;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: #1c5eca;
            }
        }
    }
}