.products {
  display: flex;
  padding: 30px 50px;

  .left {
    flex: 1;
    position: sticky;
    height: 100%;
    top: 50px;

    .filterItem {
      margin-bottom: 30px;

      h2 {
        font-weight: 400;
        margin-bottom: 20px;
      }

      .inputItem {
        margin-bottom: 10px;

        label {
          margin-right: 10px;
          padding: 5px;
        }
      }
    }
  }

  .right {
    flex: 3;


    .catImg {
      width: 99%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }

    .centered-container {
      display: flex;
      justify-content: flex-end; // Align container to the right
      width: 100%;

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; // Align items to the right
        gap: 20px;
        width: 100%; // Ensure the list takes full width of its container

        // Optionally, set a specific width for list items (adjust as needed)
        >* {
          width: calc(33.33% - 13.33px); // For 3 items per row, adjust as needed

          @media (max-width: 1024px) {
            width: calc(50% - 10px); // 2 items per row on smaller screens
          }

          @media (max-width: 768px) {
            width: 100%; // 1 item per row on mobile
          }
        }
      }
    }
  }
}