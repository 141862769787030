.categories {
  display: flex;
  height: 80vh;
  gap: 10px;
  margin: 10px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .col-l {
    flex: 2;
  }

  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;

    button {
      position: absolute;
      min-width: 100px;
      width: fit-content;
      height: 50px;
      padding: 10px;
      top: 23%;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.66);
      color: rgb(0, 0, 0);
      text-transform: uppercase;
      font-weight: 500;
      transform: rotate(8deg);
      transition: all 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }

      .link {
        color: inherit;
        text-decoration: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .subcategories {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      opacity: 0;
      transition: opacity 0.3s ease;

      button {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        margin: 0;
        min-width: 80px;
        height: 30px;
        padding: 5px;
        font-size: 0.8em;
        transform: rotate(0);
        background-color: rgba(0, 0, 0, 0.7);
        color: white;

        &:hover {
          background-color: rgba(0, 0, 0, 0.9);
        }
      }
    }

    &:hover {
      .subcategories {
        opacity: 1;
      }
    }
  }
}