.product {
  padding: 20px 50px;
  display: flex;
  gap: 50px;

  .left {
    flex: 1;
    display: flex;
    gap: 20px;

    .images {
      flex: 1;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }

    .mainImg {
      flex: 5;

      img {
        width: 100%;
        max-height: 800px;
        object-fit: cover;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    h1 {
      font-size: 30px;
      font-weight: 500;
    }

    .price {
      font-size: 30px;
      color: #2879fe;
      font-weight: 500;
    }

    .quantity {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
      }
    }

    .add {
      width: 250px;
      padding: 10px;
      background-color: #2879fe;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      cursor: pointer;
      border: none;
      font-weight: 500;
      transition: background-color 0.3s ease;

      &.added {
        background-color: #4CAF50;
      }

      &:hover {
        background-color: #1c5eca;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      color: #333;

      .description {
        font-size: 18px;
        line-height: 1.6;
        text-align: right;
        margin: 0;
      }
    }

    hr {
      width: 100%;
      border: 1px solid rgb(238, 237, 237);
    }

    .additional-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;
      color: gray;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .product {
    flex-direction: column;
    padding: 20px;

    .left {
      flex-direction: column;
    }

    .right {
      .add {
        width: 100%;
      }
    }
  }
}