.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    p {
        margin-bottom: 1rem;
        font-size: 1.1rem;
    }
}

.closeButton {
    background-color: #2879fe;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #1c5eca;
    }
}