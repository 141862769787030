.slider {
  height: calc(70vh - 48px);
  width: 100vw;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: calc(50vh - 48px);
  }

  .container {
    width: 300vw;
    height: 100%;
    display: flex;
    transition: all 1s ease;

    .slide-container {
      position: relative;
      width: 100vw;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit:cover;
        object-position: center 55%;
      }

      .custom-text {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.169);
        padding: 20px;
        border-radius: 10px;
        text-align: right;
        font-family: "Darumadrop One", sans-serif;
        font-weight: 400;
        font-style: normal;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: 768px) {
          padding: 10px;
        }

        .black-text {
          font-size: 48px;
          color: rgb(149, 203, 135);
          margin-bottom: 10px;

          @media (max-width: 1024px) {
            font-size: 36px;
          }

          @media (max-width: 768px) {
            font-size: 28px;
          }

          @media (max-width: 480px) {
            font-size: 24px;
          }
        }

        .white-text {
          font-size: 36px;
          color: white;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

          @media (max-width: 1024px) {
            font-size: 28px;
          }

          @media (max-width: 768px) {
            font-size: 22px;
          }

          @media (max-width: 480px) {
            font-size: 18px;
          }
        }
      }

      .custom-text1 {
        top: 30%;
        right: 20%;

        @media (max-width: 1024px) {
          top: 40%;
          right: 15%;
        }

        @media (max-width: 768px) {
          top: 50%;
          right: 10%;
          transform: translateY(-50%);
        }
      }

      .custom-text2 {
        top: 50%;
        right: 70%;
        transform: translateY(-50%);

        @media (max-width: 1024px) {
          right: 60%;
        }

        @media (max-width: 768px) {
          top: 50%;
          right: 10%;
          transform: translateY(-50%);
        }
      }

            .custom-text3 {
              top: 25%;
              right: 40%;
              transform: translateY(-50%);
              background-color: rgba(0, 0, 0, 0.7); // Changed to black with some opacity
      
              .black-text {
                color: rgb(149, 203, 135); // Changed to white for contrast against black background
              }
      
              .white-text {
                color: rgb(235, 235, 235); // Changed to black as requested
                text-shadow: none; // Removed text shadow for better visibility on black background
              }
      
              @media (max-width: 1024px) {
                right: 60%;
              }
      
              @media (max-width: 768px) {
                top: 50%;
                right: 10%;
                transform: translateY(-50%);
        }
      }
    }
  }

  .icons {
    width: fit-content;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    gap: 30px;
    margin: auto;

    @media (max-width: 768px) {
      gap: 15px;
      bottom: 5px;
    }

    .icon {
      width: 40px;
      height: 40px;
      border: 1px solid #999;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}