.cart {
  position: fixed;
  left: 20px;
  top: 80px;
  z-index: 999;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      color: gray;
      font-weight: 400;
      margin: 0;
    }

    .close-button {
      background: none;
      border: none;
      cursor: pointer;
      color: gray;
      transition: color 0.3s ease;

      &:hover {
        color: black;
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;

    img {
      width: 80px;
      height: 100px;
      object-fit: cover;
    }

    .details {
      flex: 1;

      h1 {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        color: gray;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .price {
        color: #2879fe;
      }
    }

    .delete {
      color: red;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background-color: #2879fe;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .reset,
    .close {
      color: red;
      font-size: 14px;
      cursor: pointer;
    }
  }
}