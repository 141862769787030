.deliveriesPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
}

.contentContainer {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
}

.title {
    text-align: center;
    color: #2879fe;
    margin-bottom: 2rem;
}

.section {
    margin-bottom: 2rem;

    h2 {
        color: #2879fe;
        margin-bottom: 1rem;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: 1rem;

            strong {
                display: block;
                margin-bottom: 0.5rem;
            }

            p {
                margin: 0.25rem 0;
            }
        }
    }

    p {
        margin-bottom: 1rem;
        line-height: 1.6;
    }
}

@media (max-width: 768px) {
    .contentContainer {
        padding: 1.5rem;
    }
}