.footer {
  margin: 100px 5% 20px;

  @media (max-width: 768px) {
    margin: 50px 5% 20px;
  }

  .top {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      gap: 30px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: justify;
      font-size: 14px;

      @media (max-width: 768px) {
        text-align: right;
      }

      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #555;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      span {
        color: gray;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }

    .left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .logo {
        color: #2879fe;
        font-weight: bold;
        font-size: 24px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

        @media (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .copyright {
        margin-right: 20px;
        font-size: 12px;
        color: gray;

        @media (max-width: 768px) {
          margin-right: 0;
          font-size: 10px;
        }
      }
    }

    .right {
      img {
        height: 50px;

        @media (max-width: 768px) {
          height: 40px;
        }
      }
    }
  }
}