.contactPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.title {
  text-align: center;
  color: #2879fe;
  margin-bottom: 2rem;
}

.contactContainer {
  display: flex;
  gap: 2rem;
}

.leftSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contactInfo {
  background-color: #f8f8f8;
  padding: 2.46rem;
  border-radius: 8px;

  h2 {
    color: #2879fe;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contactForm {
  flex: 2;

  h2 {
    color: #2879fe;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
  }

  input, textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    min-height: 150px;
  }
}

.submitButton {
  background-color: #2879fe;
  color: white;
  border: none;
  padding: 1.1rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1c5eca;
  }
}

@media (max-width: 768px) {
  .contactContainer {
    flex-direction: column;
  }

  .leftSection {
    order: 2;
  }

  .contactForm {
    order: 1;
  }
}