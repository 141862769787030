.deckCarouselWrapper {
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
    --red: #ff4444;

    background: var(--bg);
    padding: 1rem;

    .container {
        max-width: 100%;
        margin: 0 auto;
    }

    .heading {
        padding: 0.5rem 0;
        font-size: 1.5rem;
        text-align: center;
    }

    .swiper_container {
        padding: 2rem 0;
        position: relative;
    }

    .swiper-slide {
        width: 15rem;
        height: 20rem;
        position: relative;
    }

    .cardLink {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

    .cardFrame {
        width: 100%;
        height: 100%;
        border: 2px solid black;
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;
    }

    .recommendedLabel {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        background-color: var(--red);
        color: var(--white);
        padding: 0.2rem 0.5rem;
        font-size: 0.75rem;
        border-radius: 0.25rem;
        z-index: 1;
    }

    .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slider-controler {
        position: relative;
        bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-controler .swiper-button-next,
    .slider-controler .swiper-button-prev {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        background: var(--white);
        opacity: 0.5;
        border-radius: 50%;
        filter: drop-shadow(0px 4px 12px rgba(18, 28, 53, 0.1));

        &::after {
            font-size: 1rem;
            color: #222224;
        }
    }

    .slider-controler .swiper-button-prev {
        left: 1rem;
    }

    .slider-controler .swiper-button-next {
        right: 1rem;
    }

    .swiper-pagination {
        position: relative;
        width: 15rem !important;
        bottom: 1rem;
    }

    .swiper-pagination .swiper-pagination-bullet {
        opacity: 0.7;
        background: #ffffff;

        &-active {
            background: var(--primary);
        }
    }
}