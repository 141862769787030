.navbar {
  position: relative;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    @media (max-width: 768px) {
      padding: 10px 15px;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 25px;

      @media (max-width: 768px) {
        gap: 15px;
      }

      .item {
        font-size: 16px;

        @media (max-width: 768px) {
          font-size: 14px;
        }

        .link {
          text-decoration: none;
          color: #333;
        }
      }
    }

    .center {
      flex: 1;
    }

    .right {
      .phonenum {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        background-color: #a1f6a1;
        padding: 6px 12px;
        border-radius: 20px;
        transition: all 0.3s ease;

        @media (max-width: 768px) {
          font-size: 14px;
          padding: 4px 8px;
        }

        &:hover {
          background-color: #78c278;
          transform: translateY(-2px);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        }
      }

      .icons {
        display: flex;
        gap: 15px;
        color: #777;
        cursor: pointer;

        @media (max-width: 768px) {
          gap: 10px;
        }

        .cartIcon {
          position: relative;

          span {
            font-size: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #2879fe;
            color: white;
            position: absolute;
            right: -10px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 768px) {
              font-size: 10px;
              width: 16px;
              height: 16px;
              right: -8px;
              top: -8px;
            }
          }
        }
      }
    }

    .logo-item {
      .logo {
        height: 50px;
        width: auto;

        @media (max-width: 768px) {
          height: 40px;
        }
      }
    }

    .desktop-menu {
      display: flex;
      gap: 20px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .mobile-menu-toggle {
      display: none;
      cursor: pointer;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  .mobile-menu {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(-10px);
    opacity: 0;

    &.open {
      display: flex;
      transform: translateY(0);
      opacity: 1;
    }

    .item {
      margin-bottom: 10px;

      .link {
        display: block;
        padding: 10px 0;
        font-size: 16px;
        color: #333;
        text-decoration: none;
      }
    }
  }
}