.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0; // Increased padding to accommodate larger logo

    .logo-wrapper {
        width: 270px; // 3 times the original width (60px * 3)
        height: 270px; // 3 times the original height (60px * 3)
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}