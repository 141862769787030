.contact {
  background-color: #2879fe;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      text-align: center;
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    .mail {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;

      @media (min-width: 480px) {
        flex-direction: row;
      }

      input {
        padding: 10px;
        border: none;
        border-radius: 5px;
        width: 100%;

        @media (min-width: 480px) {
          border-radius: 5px 0 0 5px;
        }
      }

      button {
        padding: 10px;
        color: white;
        background: #333;
        border-radius: 5px;
        border: none;
        margin-top: 10px;
        cursor: pointer;

        @media (min-width: 480px) {
          margin-top: 0;
          border-radius: 0 5px 5px 0;
        }
      }
    }

    .error {
      color: #dea0a0;
      margin-top: 5px;
      text-align: center;
    }

    .icons {
      display: flex;
      gap: 10px;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-end;
      }

      svg {
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #e0e0e0;
        }
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 90%;
    width: 300px;

    h2 {
      margin-bottom: 10px;
    }

    button {
      margin-top: 15px;
      padding: 5px 15px;
      background-color: #2879fe;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #1c5eca;
      }
    }
  }
}